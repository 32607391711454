import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    meta: { title: 'ИУС ЦИФРА' },
    component: () => import(/* webpackChunkName: "Index" */ '../views/version3.vue'),
  },
  {
    path: '/v2',
    name: 'Version2',
    meta: { title: 'ИУС ЦИФРА' },
    component: () => import(/* webpackChunkName: "version2" */ '../views/version2.vue'),

  },
  {
    path: '/v3',
    name: 'Version3',
    meta: { title: 'ИУС ЦИФРА' },
    component: () => import(/* webpackChunkName: "version3" */ '../views/version3.vue'),

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
